import React from 'react';
import { BrowserRouter, Link, Route, Routes, useLocation } from "react-router-dom";
import Home from './pages/Home';
import Admin from './pages/Admin';
import Show from "./pages/Show";
import NotFound from './pages/NotFound';
import Login from './pages/Login';
import Register from './pages/register';

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/admin" element={<Admin/>} />
        <Route path="/show" element={<Show/>} />
        <Route path="/register" element={<Register/>} />
        <Route path="/login" element={<Login/>} />
        {/* <Route path="/logout" element={<Login/>} /> */}
        <Route path="*" element={<NotFound/>} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;