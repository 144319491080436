import React, {useEffect, useRef, useState} from "react";
import AppRouter from "./AppRouter";

// const { app, BrowserWindow } = require('electron');
const axios = require('axios');

function App(){

    return (
      <div>
        <AppRouter/>
      </div>
    )
}

// function createWindow() {
//     const mainWindow = new BrowserWindow({ width: 800, height: 600 });
//     mainWindow.loadFile('index.html');
  
//     // Retrieve device information and send it to the server
//     const macAddress = '...'; // Obtain MAC address using appropriate APIs
//     const deviceInfo = { macAddress };
  
//     axios.post('http://localhost:' + process.env.PORT + '/device-info', deviceInfo)
//       .then(response => {
//         console.log('Device information sent to server:', response.data);
//       })
//       .catch(error => {
//         console.error('Error sending device information:', error);
//       });
//   }
  
//   app.whenReady().then(createWindow);

export default App;