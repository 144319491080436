import React, { Profiler } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';

const onRenderCallback = (
  id, // the "id" prop of the Profiler tree that has just committed
  phase, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
  actualDuration, // time spent rendering the committed update
  baseDuration, // estimated time to render the entire subtree without memoization
  startTime, // when React began rendering this update
  commitTime, // when React committed this update
  interactions // the Set of interactions belonging to this update
) => {
  // Custom profiling logic goes here
};

ReactDOM.createRoot(document.getElementById('root')).render(
  <Profiler onRender={onRenderCallback}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Profiler>
);
