import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from '../components/includes/Navbar.jsx';

const Admin = () => {
    const [base64Data, setBase64Data] = useState(null);
    const [userList, setUserList] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');

    useEffect(() => {
      // Function to fetch user list from API
      const fetchUserList = async () => {
        try {
          // Make a GET request to fetch user list from backend
          const response = await axios.get(`/users?usertypeid=4`);
  
          // Update the state with the fetched user list
          setUserList(response.data);
        } catch (error) {
          // Handle error if request fails
          console.error('Error fetching user list:', error);
        }
      };
  
      // Call the fetchUserList function when the component mounts
      fetchUserList();
    }, []); // Empty dependency array to run effect only once

    const handleFileChange = (e) => {
      const file = e.target.files[0];

      if (file && !file.type.startsWith('image/')) {
        alert('Please select a valid image file.');
        return false;
      }

      const reader = new FileReader();
  
      reader.onload = (event) => {
        const base64String = event.target.result;
        setBase64Data(base64String);
      };
  
      reader.readAsDataURL(file);
    };
    
    const handleSubmit = async (e) => {
      e.preventDefault();
      
      if (!base64Data) {
        alert('Please select a file');
        return;
      }

      if(selectedUser === ''){
        alert('Please select user');
        return;
      }

      try {
        const response = await axios.post('/upload', { base64Data, user_name: selectedUser }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        // console.log(response);
        if (response.status === 200) {
          alert('File uploaded successfully');
          console.log(response.data); // Assuming the server responds with the file URL
        } else {
          alert('Error to uploading file');
        }
      } catch (error) {
        console.error('Error uploading file:', error.response.data);
        alert('Error uploading file');
      }
    };

    const imageStyles = {
      width: '100%', // Make the image responsive
      borderRadius: '8px', // Add rounded corners
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' // Add a subtle shadow
    };

    return (
      <div>
        <Navbar />
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="col-md-12">
                    <h5 className="card-title">File Upload</h5>
                  </div>
                  <div className="col-md-12">
                    {base64Data && <img style={imageStyles} src={base64Data} alt="Display File" />}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <form className='col-md-8' onSubmit={handleSubmit}>
                        <input type="file" onChange={handleFileChange} accept='image/png, image/gif, image/jpeg' />
                        <br />
                        <h2>User List</h2>
                        {Array.isArray(userList) && userList.length > 0 ? (
                          <div>
                            <label htmlFor="userSelect">Select User:</label>
                            <select
                              id="userSelect"
                              value={selectedUser}
                              onChange={e => setSelectedUser(e.target.value)}
                              className='form-control'
                            >
                              <option value="">Select a user</option>
                              {userList.map(user => (
                                <option key={user.id} value={user.user_name}>
                                  {user.user_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        ) : (
                          <p>No users available.</p>
                        )}
                        <br />
                        <button type="submit" className='btn btn-info'>Upload</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Admin;