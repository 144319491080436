import React, { useEffect, useRef, useState } from 'react';
import { io } from "socket.io-client";

const Show = () => {
    const [userFileUrl, setUserFileUrl] = useState(null);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isFullScreenButtonVisible, setIsFullScreenButtonVisible] = useState(true);
    let hideButtonTimeoutRef = useRef(null);

    useEffect(() => {
      const handleMouseMove = () => {
          setIsFullScreenButtonVisible(true); // Show the button when mouse moves
          if (hideButtonTimeoutRef.current) { // Clear any existing timeout
              clearTimeout(hideButtonTimeoutRef.current);
          }
          hideButtonTimeoutRef.current = setTimeout(() => { // Set a new timeout
              setIsFullScreenButtonVisible(false);
          }, 5000);
      };

      window.addEventListener('mousemove', handleMouseMove);

      return () => {
          window.removeEventListener('mousemove', handleMouseMove);
          if (hideButtonTimeoutRef.current) {
              clearTimeout(hideButtonTimeoutRef.current); // Clear timeout on cleanup
          }
      };
  }, []);

    const toggleFullScreen = () => {
      if (!isFullScreen) {
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) { /* Firefox */
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
          document.documentElement.webkitRequestFullscreen();
        } else if (document.documentElement.msRequestFullscreen) { /* IE/Edge */
          document.documentElement.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) { /* Firefox */
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE/Edge */
          document.msExitFullscreen();
        }
      }
      setIsFullScreen(!isFullScreen);
      setIsFullScreenButtonVisible(true);
    };

    useEffect(() => {
      if (isFullScreen) {
          // Hide the button after 5 seconds when in full screen mode
          hideButtonTimeoutRef.current = setTimeout(() => {
              setIsFullScreenButtonVisible(false);
          }, 5000);
      }
      return () => {
          if (hideButtonTimeoutRef.current) {
              clearTimeout(hideButtonTimeoutRef.current); // Clear timeout on cleanup
          }
      };
  }, [isFullScreen]);

    useEffect(()=>{
      // const socket = io(BASE_URL);
      const socket = io.connect('/');
      const userName = localStorage.getItem('username');
      
      // Event listener for successful connection
      socket.on('connect', () => {
        const dateObj = new Date();
        console.log('Connected to server on ' + dateObj.getHours() + ":" + dateObj.getMinutes() + ":" + dateObj.getSeconds());

        if(userName){
          console.log(userName);
          socket.emit('authenticate', userName);
        }

        socket.on('userfile', (base64Data)=>{
          console.log("User file received by socket from server");
          const decodedData = atob(base64Data);
    
          // Convert decoded data to Uint8Array
          const arrayBuffer = new Uint8Array(decodedData.length);
          for (let i = 0; i < decodedData.length; ++i) {
            arrayBuffer[i] = decodedData.charCodeAt(i);
          }
          
          // Create Blob object
          const blob = new Blob([arrayBuffer], { type: 'image/jpg' }); // Adjust type according to your base64 data
          
          // Create URL for Blob
          const url = URL.createObjectURL(blob);
          console.log(url);
          
          // Set the URL as image source
          setUserFileUrl(url);
        });
      });

      // Event listener for disconnection
      socket.on('disconnect', () => {
        console.log('Disconnected from server');
      });

      // Event listener for reconnecting
      socket.on('reconnect', () => {
        console.log('Reconnected to server');
      });

      // Event listener for connection error
      socket.on('connect_error', (error) => {
        console.error('Connection error:', error);
      });

      // Event listener for connection timeout
      socket.on('connect_timeout', () => {
        console.error('Connection timeout');
      });
    });

    return (
      <div style={{ height: '100vh', width: '100vw', position: 'relative' }}>
        
          {/* {imageUrl && <img src={imageUrl} alt="Converted Image" style={{ width: '100%', height: '100%' }} />} */}
          {userFileUrl && <img src={userFileUrl} alt="Show" 
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            objectFit: 'cover',
            zIndex: 999,
          }} />}
            {/* <img src={imageData} alt="" id='image' />
            <ImageConverter base64JPEG={imageData}/> */}
            {/* <p>{outputPng}</p>
            <br/> */}
            {isFullScreenButtonVisible && ( // Conditionally render the button
                <button
                    onClick={toggleFullScreen}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        zIndex: 999,
                    }}
                >
                    {isFullScreen ? 'Exit Full Screen' : 'Full Screen'}
                </button>
            )}
        </div>
    );
};

export default Show;