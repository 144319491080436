import React, { useState } from 'react';
import axios from 'axios';
import Navbar from '../components/includes/Navbar';
import { Link, useNavigate } from 'react-router-dom';

const Login = () => {
  const [user_name, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
        
    try {
      const response = await axios.post('/login', { user_name, password });
      localStorage.setItem('username', user_name);
      localStorage.setItem('usertypeid', response.user_type_id);
      localStorage.setItem('token', response.token);
      navigate('/');

      console.log(response.data);
      console.log('Login successful. Token:', response.data.token);
      // Handle successful login (e.g., redirect user)
    } catch (error) {
      console.error('Login failed:', error.response.data);
      // console.error('Login error:', error.response.data.error);
      // Handle login error (e.g., display error message)
    }
  };

  return (
    <div>
      <Navbar userTypeId={3}/>
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-4">
            <h2 className="mb-4 text-center">Login</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="username">Username</label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  value={user_name}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <br />
              <button type="submit" className="btn btn-primary">Login</button>
            </form>
            <div className="mt-3">
              <p>Don't have an account? <Link to="/register">Register</Link></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;