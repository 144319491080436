import React, { useEffect, useState } from 'react';
import Navbar from '../components/includes/Navbar';
import io from 'socket.io-client';

const Home = () => {
    const [connectedUsers, setConnectedUsers] = useState([]);

    useEffect(() => {
        const socket = io.connect('/'); // Change URL to your server's address
    
        socket.on('connect', () => {
          const dateObj = new Date();
          console.log('Connected to server on ' + dateObj.getHours() + ":" + dateObj.getMinutes() + ":" + dateObj.getSeconds());
        });
    
        socket.on('disconnect', () => {
          console.log('Disconnected from server');
        });
    
        socket.on('connectedUsers', (users) => {
            setConnectedUsers(users);
          });
    
        socket.on('userDisconnected', (userId) => {
          setConnectedUsers((prevUsers) => prevUsers.filter(user => user.ip !== userId));
        });
        // connectedUsers.map(user => (
        //   console.log("User: " + user.id)
        // ))
        // return () => {
        //   socket.disconnect();
        // };
      }, []);
    return (
        <div>
            <Navbar />
            
            <div className="container">
                <h1>Connected Users Summary</h1>
                <p>Total connected users: {connectedUsers.length}</p>
                <ul>
                {connectedUsers.map(user => (
                    <li key={user.ip}>IP: {user.ip}, User: {user.username}</li>
                ))}
                </ul>
            </div>
        </div>
    );
};

export default Home;