import React from 'react';

const Logout = ({ onLogout }) => {
    const handleLogout = () => {
        onLogout();
    };

    return (
        <button className="nav-link" onClick={handleLogout}>Logout</button>
    );
};

export default Logout;