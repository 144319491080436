import React, { useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import logo from "../../../src/image_app/interspeed.jpg";
import path from 'path';
import axios from 'axios';
import Logout from '../../pages/Logout';

function Navbar(){
    const [userTypeId, setUserTypeId] = useState(null);
    const [sessionData, setSessionData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSessionData = async () => {
            try {
                const response = await axios.get('/api/session');
                setSessionData(response.data);
                setUserTypeId(response.data.usertypeid);
                console.log("user type id from navbar: " + response.data.usertypeid);
            } catch (error) {
                console.error('Error fetching session data:', error);
            }
        };
        fetchSessionData();
    }, []);

    const [token, setToken] = useState(localStorage.getItem('token'));
    const handleLogout = () => {
        localStorage.removeItem('token');
        setToken(null);
        navigate('/login')
    };
    

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <Link className="navbar-brand" to="/">
                <img className='d-inline-block align-top' src={logo} alt="Logo" height={30} width={30} />
            </Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ml-auto">
                {token && userTypeId <= 3 && (
                    <>
                        <li className="nav-item">
                        <Link className="nav-link" to="/">Home</Link>
                        </li>
                        <li className="nav-item">
                        <Link className="nav-link" to="/admin">Admin</Link>
                        </li>
                    </>
                )}
                <li className="nav-item">
                <Link className="nav-link" to="/show">Show</Link>
                </li>
            </ul>
            <span className="navbar-text">
                {token ? (
                        <Logout onLogout={handleLogout} />
                ) : (
                        <Link className="nav-link" to="/login" style={{ right: '0' }}>Login</Link>
                )}
            </span>
            </div>
        </nav>
    );
};

export default Navbar;